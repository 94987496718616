import { Box, Container, Image} from '@chakra-ui/react'
import React from 'react'
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

import Image2 from '../../assets/image/photo_2023-02-28_16-36-28.webp'
import Image1 from '../../assets/image/photo_2023-02-28_16-37-34.webp'
import Image3 from '../../assets/image/photo_2023-02-28_16-37-43.webp'
import Image4 from '../../assets/image/photo_2023-02-28_16-37-49.webp'
import Image5 from '../../assets/image/photo_2023-02-28_16-37-55.webp'
import Image8 from '../../assets/img/Продукция-Люк с замком-2.webp'
import Image7 from '../../assets/img/Продукция-мусорный контейнер.webp'
import Image6 from '../../assets/img/Продукция-грохот-3.webp'

const Producction = ({ text }) => {
  const images = [
    {
      src: Image1,
      type: 'image',
    },
    {
      src: Image2,
      type: 'image',
    },
    {
      src: Image3,
      type: 'image',
    },
    {
      src: Image4,
      type: 'image',
    },
    {
      src: Image5,
      type: 'image',
    },
    {
      src: Image6,
      type: 'image',
    },
    {
      src: Image7,
      type: 'image',
    },
    {
      src: Image8,
      type: 'image',
    },
  ]

  const handleClick = (index) => {
    new Fancybox(images, { startIndex: index })
  }

  return (
    <section>
      <Container maxW="1220px">
        <Box
          display="flex"
          justifyContent="space-around"
          flexWrap="wrap"
          pt="20px"
        >
          {images.map((el, idx) => (
            <Box key={idx} maxW="300px" mx="5px" mb="20px">
              <Image
                src={el.src}
                cursor="pointer"
                h="250px"
                onClick={() => {
                  handleClick(idx)
                }}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </section>
  )
}

export default Producction
