import React from 'react'
import { Routes,Route } from 'react-router-dom'

//local
import {
  CastPage,
  ContactUsPage,
  ForgedPage,
  GrindingPage,
  Home,
  LaboratoryPage,
  ModelPage,
  NotFound,
  OurPhotosPage,
  RolledPage,
} from './pages'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/our-photos" element={<OurPhotosPage />} />
      <Route path="/contact" element={<ContactUsPage />} />
      <Route path="/forged-balls" element={<ForgedPage />} />
      <Route path="/cast-balls" element={<CastPage />} />
      <Route path="/rolled-balls" element={<RolledPage />} />
      <Route path="/model" element={<ModelPage />} />
      <Route path="/grinding" element={<GrindingPage />} />
      <Route path="/laboratory" element={<LaboratoryPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
