import { Box, Container, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

import SvgClock from "../../assets/svg/SvgClock";
import SvgPhone from "../../assets/svg/SvgPhone";
import SvgMail from "../../assets/svg/SvgMail";
import Logo from "../../assets/image/logoHead.png";
import SvgFacebook from "../../assets/svg/SvgFacebook";
import SvgInstagram from "../../assets/svg/SvgInstagram";
import SvgTwitter from "../../assets/svg/SvgTwitter";
import rus from "../../assets/image/russia.png";
import english from "../../assets/image/english.png";
import MobileMenu from "./MoileMenu";
import DropDown from "./Dropdown";
import DropDownLang from "./DropDownLang";
import { Trans } from "react-i18next";
import "./style.scss";
import i18n from "../../i18n/Index";
import { listSvg } from "./helper";

const Header = () => {
  const [openBurger, setOpenBurger] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalLang, setModalLang] = useState(false);
  const list = [
    {
      svg: <SvgPhone />,
      title: "+996 (312) 882 263",
      link: "tel:+996 (312) 882 263",
    },
    {
      svg: <SvgMail />,
      title: "vulkanplyus@mail.ru",
      link: "mailto:vulkanplyus@mail.ru",
    },
    {
      svg: <SvgClock />,
      title: "Пн-Пт: 9.00-18.00",
    },
  ];

  const navigate = useNavigate();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [fixedMenu, seyFixedMenu] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos > 170) {
      seyFixedMenu(true);
      setPrevScrollPos(prevScrollPos);
    } else {
      seyFixedMenu(false);
      setPrevScrollPos(currentScrollPos);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <header
      id="header"
      style={{
        marginBottom: '2px solid #BCBCBC',
      }}
      className="header__head"
    >
      <Container maxW="1220px" py="15px" zIndex="10">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box maxW="169px">
            <Image
              src={Logo}
              cursor="pointer"
              onClick={() => {
                navigate('/')
              }}
            />
          </Box>
          <Box display={{ base: 'none', lg: 'block' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {list.map((el, index) => (
                <Box
                  style={
                    index < 2
                      ? { marginRight: '60px' }
                      : { marginRight: '30px' }
                  }
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box mr="16px">{el.svg}</Box>
                  <Text
                    cursor="pointer"
                    onClick={() => {
                      if (el.link) {
                        window.location.href = el.link
                      }
                    }}
                  >
                    {el.title}
                  </Text>
                </Box>
              ))}
              {listSvg.map((el, index) => (
                <Box
                  key={index}
                  cursor="pointer"
                  borderRadius="50%"
                  onClick={() => {
                    if (el.link) {
                      window.open(el.link)
                    }
                  }}
                  style={
                    index < 2 ? { marginRight: '10px' } : { marginRight: '0' }
                  }
                >
                  {el.svg}
                </Box>
              ))}
            </Box>
          </Box>
          <button
            onClick={() => setOpenBurger(!openBurger)}
            className={`burger-button ${openBurger ? 'active' : ''}`}
          >
            <Box
              w="26px"
              rounded="10px"
              m={{ l: 'auto' }}
              h="3px"
              bg="#7E8085"
              className="burger-button-top"
            ></Box>
            <Box
              w="26px"
              rounded="10px"
              m={{ l: 'auto' }}
              h="3px"
              bg="#7E8085"
              className="burger-button-center"
            ></Box>
            <Box
              w="26px"
              rounded="10px"
              h="3px"
              bg="#7E8085"
              className="burger-button-bottom"
            ></Box>
          </button>
        </Box>
        <MobileMenu
          className={openBurger ? 'transform' : ''}
          setOpenBurger={setOpenBurger}
        />
      </Container>
      <Box
        bg="#3f3f3f"
        display={{ base: 'none', lg: 'block' }}
        position={fixedMenu ? 'fixed' : 'static'}
        top="0"
        left="0"
        w="100%"
        zIndex="35"
        className={fixedMenu ? 'black_nav_menu' : 'black_menu_none'}
      >
        <Container maxW="1220px">
          <Box display="flex" justifyContent="center" alignItems="center">
            <NavLink to="/">
              <Text
                color="white"
                position="relative"
                mr="30px"
                className="item"
                cursor="pointer"
                fontSize="15px"
                fontWeight="700"
                py="25px"
              >
                <Trans>AboutCompany</Trans>
              </Text>
            </NavLink>
            <Box position="relative" mr="30px">
              <Box
                color="white"
                className="item"
                cursor="pointer"
                fontSize="15px"
                fontWeight="700"
                onMouseEnter={() => setModal(true)}
                onMouseLeave={() => setModal(false)}
                display="flex"
                alignItems="center"
                py="25px"
              >
                <Trans>ourProduct</Trans>
                <IoIosArrowDown style={{ marginLeft: '10px' }} />
                {modal && <DropDown modal={modal} setModal={setModal} />}
              </Box>
            </Box>
            <Box mr="30px">
              <NavLink to="/our-photos">
                <Text
                  color="white"
                  className="item"
                  position="relative"
                  cursor="pointer"
                  fontSize="15px"
                  fontWeight="700"
                  py="25px"
                >
                  <Trans>ourPhoto</Trans>
                </Text>
              </NavLink>
            </Box>
            <Box mr="30px">
              <NavLink to="/contact">
                <Text
                  color="white"
                  className="item"
                  cursor="pointer"
                  position="relative"
                  fontSize="15px"
                  fontWeight="700"
                  py="25px"
                >
                  <Trans>contactUs</Trans>
                </Text>
              </NavLink>
            </Box>
            <Box position="relative">
              <Box
                color="white"
                mr="10px"
                className="item"
                cursor="pointer"
                fontSize="15px"
                fontWeight="700"
                display="flex"
                alignItems="center"
                py="25px"
                onMouseEnter={() => setModalLang(true)}
                onMouseLeave={() => setModalLang(false)}
              >
                <Image
                  src={i18n.language === 'ru' ? rus : english}
                  w="16px"
                  h="12px"
                  display="inline-block"
                  mr="5px"
                />
                <Trans>lang</Trans>
                <IoIosArrowDown style={{ marginLeft: '10px' }} />
              </Box>
              {modalLang && (
                <DropDownLang modal={modalLang} setModal={setModalLang} />
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </header>
  )
};

export default Header;
