import { Box, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import SvgArrow from "../../assets/svg/SvgArrow";
import "./style.scss";

export default function DropDown({ modal, setModal }) {
  const { t } = useTranslation();

  const listSelect = [
    {
      link: "/forged-balls",
      item: "forgedGrindingBalls",
      svg: <SvgArrow />,
    },

    {
      link: "/cast-balls",
      item: "castGrindingBalls",
      svg: <SvgArrow />,
    },
    {
      link: "/rolled-balls",
      item: "rolled",
      svg: <SvgArrow />,
    },
    {
      link: "/model",
      item: "modelCasting",
      svg: <SvgArrow />,
    },
    {
      link: "/grinding",
      item: "grindingBalls",
      svg: <SvgArrow />,
    },
    {
      link: "/laboratory",
      item: "laboratoryServices",
      svg: <SvgArrow />,
    },
  ];

  return (
    <Box
      onMouseEnter={() => setModal(true)}
      onMouseLeave={() => setModal(false)}
    >
      <Box
        className={`modal ${modal ? "active" : ""}`}
        h="253px"
        bg="white"
        shadow="0px 4px 6px rgba(0, 0, 0, 0.25)"
        roundedBottom="5px"
        zIndex="10"
      >
        <Box pl="30px" mx="auto" display="flex" h="100%" alignItems="center">
          <Box>
            {listSelect.map((el, index) => (
              <Box my="14px" alignItems="center" key={index} display="flex">
                <NavLink
                  to={el.link}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <SvgArrow />
                  <Text ml="10px" fontSize="14px" color="#A4A4A4">
                    <Trans>{el.item}</Trans>
                  </Text>
                </NavLink>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
