import React from 'react'

const SvgGalochka = () => {
  return (
    <svg
      style={{ width: '100%' }}
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.7562 36.9219C50.6312 36.9219 36.7562 50.9219 36.7562 67.9219C36.7562 85.0469 50.6312 98.9219 67.7562 98.9219C84.7562 98.9219 98.7562 85.0469 98.7562 67.9219C98.7562 50.9219 84.7562 36.9219 67.7562 36.9219ZM67.7562 42.9219C81.5062 42.9219 92.7562 54.1719 92.7562 67.9219C92.7562 81.7969 81.5062 92.9219 67.7562 92.9219C53.8812 92.9219 42.7562 81.7969 42.7562 67.9219C42.7562 54.1719 53.8812 42.9219 67.7562 42.9219ZM85.2562 59.2969L82.3812 56.4219C81.8812 55.7969 80.8812 55.7969 80.2562 56.4219L62.6312 73.9219L55.1312 66.4219C54.5062 65.7969 53.6312 65.7969 53.0062 66.4219L50.1312 69.1719C49.6312 69.7969 49.6312 70.7969 50.1312 71.2969L61.5062 82.7969C62.1312 83.4219 63.0062 83.4219 63.6312 82.7969L85.2562 61.4219C85.7562 60.7969 85.7562 59.7969 85.2562 59.2969Z"
        fill="#FFAE00"
      />
      <rect
        x="2.15625"
        y="2.42188"
        width="131"
        height="131"
        stroke="#FFAE00"
        strokeWidth="3"
      />
    </svg>
  )
}

export default SvgGalochka
