import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const langs = JSON.parse(localStorage.getItem('lang'))

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        adress:
          'Kyrgyz Republic, 722300. Chui region, Zhaiyl district. city ​​of Kara-Balta, st. Kozhomberdiev 3.',
        advantage: 'COMPANY ADVANTAGES',
        AboutCompany: 'About company',
        available:
          'Available sizes and mechanical characteristics of rolled grinding balls:',
        based: '4. Based on the data received , recommendations are given.',
        become: 'Become a benchmark in quality and partnership.',
        callUs: 'Call us',
        customer: "CUSTOMER'S INDIVIDUAL APPROACH",
        cooperation: 'COOPERATION',
        carrying:
          '1. Carrying out spectral analysis to determine the chemical composition of the metal on a Iskroline-100 spectrometer',
        castGrindingBalls: 'Cast Grinding Balls',
        castGrindingDesc:
          'Cast grinding balls are made of high chrome, medium chrome and low chrome cast iron , and have different degrees of hardness depending on the chromium content.',
        castGrindingBallsAre:
          'Cast grinding balls are used in the mining industry, in the production of cement, etc.',
        chemical:
          'Chemical composition of material for production of rolled grinding balls:',
        determination:
          '2. Determination of hardness by an electronic hardness tester “TEMP-2”',
        determinationOfImpact: '3. Determination of impact strength.',
        developedInMotionWeb: 'developed by motion web llc',
        knocking: '7. Knocking out molds from flasks after cooling.',
        forgedGrindingBalls: 'Forged Grinding Balls',
        forgedBalls:
          'Forged balls are made of steel ingots using a forging hammer. These balls are characterized by increased hardness and wear resistance.',
        forming: '4. Forming in flasks with quartz sand on a vibrating table.',
        fromTheFirstDays:
          "From the first days of its operation, the company has beenBecome a benchmark in quality and partnership.cooperating with various international experts and organizations in order to bring the company's vision to life, which states that Vulkan Plus should become the leading company in the field of production of iron and steel castings in the Central Asian market .",
        fullName: 'Full name',
        grindingBalls: 'Foundry production',
        grindingBallsDesc: '',
        grindingBallProduction: 'Grinding Ball Production Technology',
        gasification:
          'Gasification casting is a method of producing castings using a model made of a material that is gasified by pouring molten metal into a mold.',
        high: 'HIGH LEVEL OF SERVICE',
        heating: '5. Heating blanks and forging balls 110 mm.',
        highChromium: '1. high chromium cast iron',
        heat: '9. Heat treatment of finished products (if necessary).',
        howWeAreWorking: 'How We Are Working',
        heatTreatment: '6. Heat treatment.',
        impact: 'Impact strength (j/cm2)',
        ourAdress: 'Our Adress',
        ourProduct: 'Our product',
        ourPhoto: 'Our Factory',
        ourPartners: 'Our Partners',
        contactUs: 'Contact us',
        cutting: '8. Cutting the gate system.',
        cuttingOfRolled:
          '4. Cutting of rolled products into blanks for forging balls 110 mm.',
        lang: 'English',
        laboratoryServices: 'Laboratory Services',
        lowChromium: '3. Low chromium cast iron',
        normalDiameter: 'Nominal diameter (mm)',
        metal: '1. Metal smelting in an induction furnace.',
        metalCutting: '3. Metal cutting of a square and hire to f 75mm.',
        medium: '2. Medium Chrome Cast Iron',
        melting: '5. Melting metal in an induction furnace.',
        modelCasting: 'Model Casting',
        mobile: 'Mobile',
        manufacture:
          '2.Manufacture of foam models according to customer drawings.',
        phone: 'Phone',
        pouring:
          '2. Pouring molten metal into a mold and rolled metal of a 100x100mm square.',
        professionalTeam: 'PROFESSIONAL TEAM',
        pproductionOfGrinding: 'PRODUCTION OF GRINDING BALLS FOR BALL MILLS',
        painting: '3. Painting models with heat-resistant paint.',
        rolledGrindingBalls: 'Rolled Grinding Balls',
        sendUsAMessage: 'Send us a message',
        quality: 'QUALITY OF PERFORMANCE',
        qualityHigh:
          'HIGH QUALITY , TAKING INTO ACCOUNT THE INTERESTS OF THE CUSTOMER. . .',
        rolled: 'Rolled Grinding Balls',
        rolledGrinding:
          'Rolled grinding balls are made of steel bars of a ball-rolling mill. These balls have a high degree of hardness.',
        surface: 'Surface hardness',
        tolerance: 'Tolerance for diameter (mm)',
        theMost: 'The most common material for models is Styrofoam.',
        theCompany:
          'LLC "VULKAN plus" was established in 2011 and specializes in the production of grinding balls for grinding equipment.',
        theLaboratory:
          'The company’s laboratory is also equipped with modern measuring instruments to maintain quality at a constantly high level.',
        theChemical:
          'The chemical composition of the material from which the cast balls are made: ',
        theConclustion: '1. The conclusion of the contract with the customer.',
        theMainActivity:
          'For 12 years of fruitful work, we have achieved our goal of becoming one of the most professional supplier in the country high-quality consumables for grinding equipment.',
        theVulkanPlusCompanyHasBeenOperatingSteadilySince:
          'The Vulkan Plus company has been operating steadily since 2013 and since then the quality of its products and the volume of production has been continuously increasing.',
        theProduction:
          'The production uses modern equipment and best practices from leading manufacturers. The company’s laboratory is also equipped with modern measuring instruments to maintain quality at a constantly high level.',
        theyAreUsed:
          'They are used in the mining industry , in the production of cement , ect.',
        used: 'Used in the development of ores of ferrous and non-ferrous metal in the production of cement , in the production of fire stops , etc.',
        vulkanPlusCompany: 'VULKAN PLUS COMPANY',
        // workExperience: "WORK EXPERIENCE - 9 YEARS",
        workExperience: 'ACTIVITIES SINCE 2011',
        companyName: 'LLC "VULKAN plus"',
        btnPodrob: 'More',
        theActive:
          'Over 12 years of fruitful work, we have achieved our goal of becoming one of the most professional supplier of high-quality consumables for grinding equipment in the country. Our plant annually produces from 10,000 to 12,000 tons of products on average. Pays taxes and social contributions to the state budget in the amount of more than 30,000,000 soms per year.',
        companyDesc: 'steel plant',
        nextTech:
          'The following casting technologies have been mastered at the enterprise:',
        earthLit:
          '- casting into the ground - casting into a metal mold - casting on gasified models - the plant was put into production continuous casting of steel, with a rolling mill to obtain round blanks of measured length. In addition to foundry technologies introduced into the production of forging balls on a hammer, on special matrix.',
        sehTerr:
          'On the territory of the plant there are: foundry workshop and hardening shop; continuous casting shop; rolling shop; model shop; blacksmith shop; molding shop; mechanical shop processing, equipped with turning and milling machines.',
        equippedWith:
          'The company is equipped with all necessary auxiliary equipment:',
        thermalFurnaces:
          '- thermal furnaces (bogie hearth) for annealing, hardening and tempering - finishing equipment, - mixing equipment - welding and guillotine equipment',
        qualityProducts:
          'To check the quality of products, our laboratory is equipped with testing and control equipment:',
        stableWork:
          "Stable work since 2011 on the casting of grinding balls for ball mills can be considered important stages in the development of Vulkan Plus LLC. The consumers of our products are the flagships of the country's gold mining industry, such as Kumtor Gold Company CJSC, Eti Bakyr Tereksai LLC, Makmal Gold Company LLC, Altynken LLC and Altyn Alliance LLC.",
        developmentOrder:
          'For the purpose of its development and in order to increase its competitiveness, our company introduces an automatic flaskless molding line into production (similar to DISA MATCH). Flaskless molding is used for the manufacture of small (up to 4 kg) castings, ensures high accuracy and quality. The equipment has been brought and assembled at the plant in Kara Balta. Estimated launch time of the line is the 2nd quarter of 2023.',
        companyProduces:
          'In addition to balls, the company produces consumables for grinding equipment: - armor plates - movable and fixed cones - linings for impact crushers - gratings for ball mills, etc. Parts are produced by casting according to gasified patterns from gray cast iron, wear-resistant Hadfield steel, wear-resistant chromium cast iron, heat-resistant cast iron, etc. Weight of cast parts from 200 grams to 1200 kg. At the request of the Customer, we perform heat treatment of castings - annealing, hardening, tempering.',
        finalCharacteristics:
          'The production process of our grinding balls, as well as their final characteristics, comply with GOST 7524-2015. Balls with a diameter of 80 to 125 mm are produced by hammer forging. The control of the size of the balls is carried out selectively - before hardening and after heat treatment. Maximum deviations from the nominal diameter within the requirements of GOST 7524-2015. Cast iron balls with a diameter of 12 to 45 mm are produced by casting, the raw material for them is cast iron ingots of our own remelting, obtained in induction melting furnaces.',
        fullySupplied:
          'The company is fully supplied with water. We have our own artesian well, there are 3 hydro pools for technological needs. The plant is provided with all the necessary conditions for engineers and workers, there is a dining room with a kitchen, rest rooms, shower rooms, an orchard and green spaces.',
        scrapMetal:
          'Transportation of scrap metal (loading, unloading, sorting) is carried out using an overhead crane and a reloader. It has its own fleet with a car wash of dump trucks, loaders and excavators.',
        warehouseOccupies:
          'The scrap metal warehouse occupies 2 territories with a total area of ​​about 20,000 m2.',
        productionAuxiliary:
          'Production of auxiliary equipment, fixtures, etc. engaged in machining workshop, equipped with turning and milling machines.',
        hardnessTester:
          '- Hardness tester, for measuring hardness by the Rockwell-Brinell method, portable. – Spectrometer, for the analysis of the chemical composition of steel, cast iron - optical emission, stationary - Pyrometers, for control thermal regimes, narrowly spectral. - Measuring tool, to control linear dimensions.',
        btnClose: 'Close',
      },
    },
    ru: {
      translation: {
        adress:
          'Кыргызская Республика, 722300. Чуйский область, Жайылский район. город Кара-Балта,ул .Кожомбердиева 3.',
        advantage: 'ПРЕИМУЩЕСТВА КОМПАНИИ',
        AboutCompany: 'О компании',
        available:
          'Доступные размеры и механические характеристики катаных мелющих шаров:',
        based: '4. На основании полученных данных даются рекомендации.',
        become: 'Станьте эталоном качества и партнерства.',
        callUs: 'Позвоните нам',
        customer: 'ИНДИВИДУАЛЬНЫЙ ПОДХОД К КЛИЕНТУ',
        cuttingOfRolled:
          '4. Порезка проката на заготовки для ковки шаров 110 мм.',
        cooperation: 'СОТРУДНИЧЕСТВО',
        carrying:
          '1. Проведение спектрального анализа для определения химического состава металла на спектрометре «Искролайн-100».',
        castGrindingBalls: 'Литые шлифовальные шары',
        castGrindingDesc:
          'Литые мелющие шары изготавливаются из высокохромистого, среднехромистого и низкохромистого чугуна и имеют разную степень твердости в зависимости от содержания хрома.',
        castGrindingBallsAre:
          'Литые мелющие шары применяются в горнодобывающей промышленности, при производстве цемента и т.д.',
        chemical:
          'Химический состав сырья для производства катаных мелющих шаров:',
        contactUs: 'Связаться с нами',
        cutting: '8. Резка воротной системы.',
        determination:
          '2. Определение твердости электронным твердомером «ТЕМП-2»',
        determinationOfImpact: '3. Определение ударной вязкости.',
        developedInMotionWeb: 'разработан в motion web llc',
        knocking: '7. Выбивание форм из опоки после охлаждения.',
        forgedGrindingBalls: 'Кованые мелющие шары',
        forgedBalls:
          'Кованые шары изготавливаются из стальных слитков с помощью кузнечного молота. Эти шары отличаются повышенной твердостью и износостойкостью.',
        forming: '4. Формовка в опоках с кварцевым песком на вибростоле.',
        fromTheFirstDays:
          'С первых дней своей работы компания стала эталон качества и партнерства.сотрудничество с различными международных экспертов и организаций для того,чтобы видение жизни компании,в котором говорится,что Vulkan Plus должен стать ведущей компанией в области производства чугуна и стальное литье на рынке Центральной Азии.',
        fullName: 'Полное имя',
        grindingBalls: 'Литейное производство',
        grindingBallsDesc: '',
        grindingBallProduction: 'Технология производства мелющих шаров',
        gasification:
          'Газификационное литье — способ производства отливок по модели из материала, который газифицируется путем заливки расплавленного металла в форму.',
        high: 'ВЫСОКИЙ УРОВЕНЬ СЕРВИСА',
        highChromium: '1. Высокохромистый чугун',
        heat: '9. Термическая обработка готовой продукции (при необходимости).',
        heating: '5. Нагрев заготовок и ковочных шаров 110 мм.',
        heatTreatment: '6. Термическая обработка.',
        howWeAreWorking: 'Как мы работаем',
        impact: 'Ударная вязкость (Дж/см2)',
        ourAdress: 'Наш Адрес',
        ourProduct: 'Наша продукция',
        ourPhoto: 'Наш завод',
        ourPartners: 'Наши партнеры',
        lang: 'Русский',
        laboratoryServices: 'Лабораторные услуги',
        lowChromium: '3. Низкохромистый чугун',
        normalDiameter: 'Номинальный диаметр (мм)',
        metal: '1. Плавка металла в индукционной печи.',
        metalCutting: '3. Порезка металла квадрата и проката до ф 75мм.',
        medium: '2. Среднехромистый чугун',
        manufacture:
          '2.Изготовление моделей из пенопласта по чертежам заказчика.',
        modelCasting: 'Кастинг моделей',
        mobile: 'Мобильный',
        melting: '5. Плавка металла в индукционной печи.',
        phone: 'Телефон',
        pouring:
          '2. Заливка расплавленного металла в форму и прокатка квадрата 100х100мм.',
        professionalTeam: 'ПРОФЕССИОНАЛЬНАЯ КОМАНДА',
        pproductionOfGrinding:
          'ПРОИЗВОДСТВО ПОМОЛЬНЫХ ШАРОВ ДЛЯ ШАРОВЫХ МЕЛЬНИЦ',
        painting: '3. Покраска моделей термостойкой краской.',
        rolledGrindingBalls: 'Катаные мелющие шары',
        sendUsAMessage: 'Отправьте нам сообщение',
        quality: 'КАЧЕСТВО ВЫПОЛНЕНИЯ',
        qualityHigh: 'ВЫСОКОКАЧЕСТВЕННО, С УЧЕТОМ ИНТЕРЕСОВ ЗАКАЗЧИКА. . .',
        rolled: 'Катаные мелющие шары',
        rolledGrinding:
          'Катаные мелющие шары изготавливаются из стальных прутков шаропрокатного стана. Эти мячи имеют высокую степень твердости.',
        surface: 'Твердость поверхности',
        tolerance: 'Допуск на диаметр (мм)',
        theMost: 'Самый распространенный материал для моделей - пенопласт.',
        theLaboratory:
          'Лаборатория компании также оснащена современными измерительными приборами для поддержания качества на постоянно высоком уровне.',
        theCompany:
          'Предприятие ОсОО «ВУЛКАН плюс» было создано в 2011 году и специализируется на производстве мелющих шаров для помольного оборудования.',
        theChemical:
          'Химический состав материала, из которого изготавливаются литые шары:',
        theConclustion: '1. Заключение договора с заказчиком.',
        theMainActivity:
          'За 12 лет плодотворного труда мы достигли нашей цели-стать одним из самых профессиональных в стране поставщиком высококачественных расходных материалов для помольного оборудования.',
        theVulkanPlusCompanyHasBeenOperatingSteadilySince:
          'Компания «Вулкан Плюс» стабильно работает с 2013 года и с тех пор качество ее продукции и объем производства непрерывно увеличивается.',
        theProduction:
          'В производстве используется современное оборудование и передовой опыт ведущих производителей. Лаборатория компании оснащена современными измерительными приборами для поддержания качества на постоянно высоком уровне.',
        theyAreUsed:
          'Они используются в горнодобывающей промышленности,при производстве цемента и т.д.',
        used: 'Используется при разработке руд черных и цветных металлов, в производстве цемента, в производстве противопожарных затворов и т.д.',
        vulkanPlusCompany: 'КОМПАНИЯ ВУЛКАН ПЛЮС',
        workExperience: 'ДЕЯТЕЛЬНОСТЬ С 2011 ГОДА',
        companyName: 'ОсОО "ВУЛКАН плюс"',
        btnPodrob: 'Подробнее',
        theActive:
          'За 12 лет плодотворного труда мы достигли нашей цели-стать одним из самых профессиональных в стране поставщиком высококачественных расходных материалов для помольного оборудования. Наш завод в среднем за год выпускает от 10 000 до 12 000 тонн продукции. Оплачивает налоги и социальные отчисления в бюджет государства в размере более 30 000 000 сомов в год.',
        companyDesc: 'металлургический завод',
        nextTech: 'На предприятии освоены следующие литейные технологии:',
        earthLit:
          '- литье в землю - литье в металлический кокиль - литье по газифицированным моделям (ЛГМ) - запущена в производство установка непрерывной разливки стали (УНРС), с прокатным станом для получения круглых заготовок мерной длины. В дополнение к литейным технологиям внедрена в производство ковка шаров на молоте, на спец. матрице.',
        sehTerr:
          'На территории завода находятся: цех литейного производства и закалочный цех; цех непрерывного литья; прокатный цех; модельный цех; кузнечный цех; цех безоповочной формовки; цех механической обработки, оснащенный токарными и фрезерными станками.',
        equippedWith:
          'Предприятие оснащено всем необходимым вспомогательным оборудованием:',
        thermalFurnaces:
          '- термические печи (с выкатным подом) для отжига, закалки и отпуска - галтовочное оборудование, - смесеприготовительное оборудование - сварочное и гильотинное оборудование',
        qualityProducts:
          'Для проверки качества продукции наша лаборатория оснащена испытательным и контрольным оборудованием:',
        stableWork:
          'Важными этапами развития ОсОО «Вулкан Плюс» можно считать стабильную работу на протяжении с 2011 года по литью мелющих шаров для шаровых мельниц. Потребители нашей продукции являются флагманы золоторудной отрасли страны, как ЗАО «Кумтор Голд Компани», ОсОО Эти Бакыр Терексай, ОсОО Макмал Голд Компани, ОсОО Алтынкен и ОсОО Алтын Альянс.',
        developmentOrder:
          'В целях своего развития и в целях повышения конкурентоспособности наше предприятие внедряет в производство автоматическую линию безопочной формовки (по аналогии с «DISA MATCH»). Безопочная формовка применяется для изготовления мелких (до 4 кг) отливок, обеспечивает высокую точность и качество. Оборудование привезено и собирается на заводе в Кара Балте. Ориентировочное время запуска линии – 2 квартал 2023 года.',
        companyProduces:
          'Помимо шаров предприятие выпускает расходные материалы для помольного оборудования: - бронеплиты - конуса подвижные и неподвижные - футеровки на роторные дробилки - решетки на шаровые мельницы и т.д. Детали производим методом литья по газифицированным моделям из серого чугуна, износостойкой стали Гадфильда, износостойкого хромистого чугуна, жаропрочного чугуна и т.д. Масса отливаемых деталей от 200 грамм до 1200 кг. По желанию Заказчика производим термическую обработку отливок – отжиг, закалка, отпуск.',
        finalCharacteristics:
          'Процесс производства наших мелющих шаров, а также их конечные  характеристики соответствуют ГОСТ 7524-2015. Шары диаметром от 80 до  125 мм производятся методом ковки на молоте. Контроль размеров шаров проводится выборочно- до закалки и после термообработки. Максимальные отклонения от номинального диаметра в пределах требований ГОСТ 7524-2015. Чугунные шары диаметром от 12 до 45 мм производятся литьем, исходным сырьем для них служат чугунные чушки собственного переплава, полученный в индукционных плавильных печах.',
        fullySupplied:
          'Предприятие полностью обеспечено водой. У нас функционирует собственная артезианская скважина, для технологических нужд имеются 3 гидробассейна.Завод обеспечен всеми необходимыми условиями для ИТР  и рабочего персонала, есть столовая с кухней, комнаты отдыха, душевые комнаты, плодовый сад и зеленые насаждения.',
        scrapMetal:
          'Транспортировка металлолома (погрузка, выгрузка, сортировка) выполняется с помощью мостового крана и перегружателя. Имеется собственный автопарк с автомойкой из самосвалов, погрузчиков и экскаватаров.',
        warehouseOccupies:
          'Склад металлолома занимает 2 территории, общей площадью около 20 000 м2.',
        productionAuxiliary:
          'Изготовлением вспомогательной оснастки, приспособлений и т.д. занимается цех механической обработки, оснащенный токарными и фрезерными станками.',
        hardnessTester:
          '—Твердомер, для измерения твёрдости по методу Роквелла-Бринелля портативный. — Спектрометр, для анализа химического состава стали, чугуна - оптико-эмиссионный, стационарный — Пирометры, для контроля термических режимов, узко спектральные. — Инструмент мерительный, для контроля линейных размеров.',
        btnClose: 'Закрыть',
      },
    },
  },
  fallbackLng: 'en',
  lng: 'en',
  interpolation: { escapeValue: false },
})

export default i18n
