import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PageRouting from './components/layout/PageRouting'
import { theme } from './components/layout/theme'
import App from './App'
import './index.scss'
import "./i18n/Index"

const root = createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <PageRouting>
          <App />
        </PageRouting>
      </ChakraProvider>
    </BrowserRouter>
  </StrictMode>
)
