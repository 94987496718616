import React, { useEffect, useState } from 'react'
import {
  ContactUsComponent,
  FormContactUs,
  MapLocation,
} from '../../components'
import Loading from '../../components/loading/Loading'

const ContactUsPage = () => {
  const [load, setLoad] = useState(true)
  useEffect(() => {
    setLoad(false)
  }, [])
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <MapLocation />
          <FormContactUs />
          <ContactUsComponent />
        </>
      )}
    </>
  )
}

export default ContactUsPage
