import { Box, Container, Image, Text } from '@chakra-ui/react'
import React from 'react'
import Slider from 'react-slick'
import { Trans, useTranslation } from "react-i18next";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './style.scss'
import Image1 from '../../../assets/image/Kumtor.webp'
import Image2 from '../../../assets/image/EuropanBank.webp'
import Image3 from '../../../assets/image/ElekctroStansii.webp'
import Image4 from '../../../assets/image/Sement.webp'

const OurPartners = () => {
  const { t } = useTranslation();

  const list = [
    {
      image: Image1,
    },
    {
      image: Image2,
    },
    {
      image: Image3,
    },
    {
      image: Image4,
    },
  ]

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false
  }

  return (
    <section>
      <Container maxW="1220px" mb="126px">
        <Text
          textAlign="center"
          mb="14px"
          color="#1B1A1A"
          fontWeight="700"
          fontSize="48px"
        >
          <Trans>ourPartners</Trans>
        </Text>
        <Box w="100%" h="2px" bg="orange" mb="60px"></Box>
        <Box display="flex"></Box>
        <Slider {...settings}>
          {list.map((el, index) => (
            <Box p="5px" key={index}>
              <Box>
                <Image src={el.image} />
              </Box>
            </Box>
          ))}
        </Slider>
      </Container>
    </section>
  )
}

export default OurPartners
