import { Box, Container, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const ForgedAviable = () => {
  const { t } = useTranslation();

  const materialForged = [
    { text: "C", chislo: "0,47-0,55" },
    { text: "Si", chislo: "0,17-0,37" },
    { text: "Mn", chislo: "0,5-0,8" },
    { text: "Ni", chislo: "≤0,25" },
    { text: "S", chislo: "≤0,035" },
    { text: "P", chislo: "≤0,035" },
    { text: "Cr", chislo: "≤0,25" },
    { text: "Cu", chislo: "≤0,25" },
    { text: "As", chislo: "≤0,08" },
    { text: "Fe", chislo: "~97" },
  ];

  const availableSize = [
    {
      theme: "normalDiameter",
      block1: [{ text1: "80", text2: "90", text3: "100" }],
      block2: [{ text1: "110", text2: "120", text3: "130" }],
      block3: [{ text1: "140", text2: "150" }],
    },
    {
      theme: "tolerance",
      block1: [{ text1: "+3, -2" }],
      block2: [{ text1: "+4, -2" }],
      block3: [{ text1: "+4, -3" }],
    },
    {
      theme: "surface",
      block1: [{ text1: "55-68" }],
      block2: [{ text1: "50-63" }],
      block3: [{ text1: "50-63" }],
    },
    {
      theme: "impact",
      block1: [{ text1: "≥12" }],
      block2: [{ text1: "≥12" }],
      block3: [{ text1: "≥12" }],
    },
  ];

  return (
    <section>
      <Container maxW="1220px">
        <Box pb={["20px", "30px"]}>
          <Box
            border="solid"
            borderColor="rgba(245,167,0,1)"
            borderLeft="0px"
            borderRight="0px"
            borderTop="0px"
            mb="30px"
          >
            <Text
              textAlign="center"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["20px", "23px"]}
              lineHeight="34px"
              py={["20px", "30px"]}
              px={["10px", "20px"]}
            >
              <Trans>theyAreUsed</Trans>
            </Text>
          </Box>
          <Box>
            <Box
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["12px", "16px", "18px"]}
              bg="rgba(245,167,0,1)"
              py={["10px", "10px", "20px"]}
            >
              <Text textAlign="center" px={["10px"]}>
                <Trans>available</Trans>
              </Text>
            </Box>
            <Box
              display="flex"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["10px", "12px", "14px"]}
            >
              {availableSize.map((el, idx) => (
                <Box key={idx} w="33.33%">
                  <Box
                    fontFamily="Roboto Slab"
                    fontWeight="600"
                    fontSize={["12px", "14px", "16px", "18px"]}
                    bg="rgba(245,167,0,1)"
                    h={["70px", "50px"]}
                  >
                    <Text textAlign="center">
                      <Trans>{el.theme}</Trans>
                    </Text>
                  </Box>
                  <Box w="100%">
                    {el.block1.map((elem, idx) => (
                      <Box
                        key={idx}
                        border="solid 1px"
                        borderColor="rgba(245,167,0,1)"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        px={["2px", "10px", "20px"]}
                        h={["60px"]}
                      >
                        <Text textAlign="center">{elem.text1}</Text>
                        <Text>{elem?.text2}</Text>
                        <Text>{elem?.text3}</Text>
                        <Text>{elem?.text4}</Text>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    w="100%"
                    border="solid 1px"
                    borderColor="rgba(245,167,0,1)"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    px={["2px", "10px", "20px"]}
                    h={["60px"]}
                  >
                    {el.block2.map((elem, idx) => (
                      <Box key={idx}>
                        <Text textAlign="center">{elem.text1}</Text>
                        <Text>{elem?.text2}</Text>
                        <Text>{elem?.text3}</Text>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    w="100%"
                    h={["90px", "60px"]}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="solid 1px"
                    borderColor="rgba(245,167,0,1)"
                  >
                    {el.block3.map((elem, idx) => (
                      <Box key={idx}>
                        <Text textAlign="center">{elem.text1}</Text>
                        <Text textAlign="center">{elem?.text2}</Text>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box h="3px" w="100%" my="30px" bg="rgba(245,167,0,1)"></Box>
          <Box>
            <Box
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["12px", "16px", "18px"]}
              bg="rgba(245,167,0,1)"
              py={["10px", "20px"]}
            >
              <Text textAlign="center" px={["10px"]}>
                <Trans>chemical</Trans>
              </Text>
            </Box>
            <Box
              w="100%"
              display="flex"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["8px", "12px", "14px"]}
            >
              {materialForged.map((el, idx) => (
                <Box key={idx} w="30%" display="flex" flexDirection="column">
                  <Box
                    w="100%"
                    px={["2px", "2px", "10px"]}
                    py="10px"
                    border="solid 1px"
                    borderColor="rgba(245,167,0,1)"
                  >
                    <Text textAlign="center">{el.text}</Text>
                  </Box>
                  <Box
                    px={["1px", "2px", "5px", "10px"]}
                    py="10px"
                    border="solid 1px"
                    borderColor="rgba(245,167,0,1)"
                    h="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text textAlign="center">{el.chislo}</Text>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default ForgedAviable;
