import React, { useEffect, useState } from "react";
import { HeroComponent, OurPhotosComponent, SendUs } from "../../components";
import OurPhotoHero from "../../assets/img/ourPhotoHero.webp";
import Loading from "../../components/loading/Loading";

const OurPhotosPage = () => {
  const text = "ourPhoto";
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setLoad(false);
  }, []);
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <HeroComponent text={text} image={OurPhotoHero} />
          <OurPhotosComponent />
          <SendUs />
        </>
      )}
    </>
  );
};

export default OurPhotosPage;
