export default function SvgPeople() {
  return (
    <svg
      width="114"
      height="90"
      viewBox="0 0 114 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5062 39.625C23.6586 39.625 28.7562 34.7031 28.7562 28.375C28.7562 22.2227 23.6586 17.125 17.5062 17.125C11.1781 17.125 6.25625 22.2227 6.25625 28.375C6.25625 34.7031 11.1781 39.625 17.5062 39.625ZM96.2562 39.625C102.409 39.625 107.506 34.7031 107.506 28.375C107.506 22.2227 102.409 17.125 96.2562 17.125C89.9281 17.125 85.0062 22.2227 85.0062 28.375C85.0062 34.7031 89.9281 39.625 96.2562 39.625ZM101.881 45.25H90.6312C87.4672 45.25 84.6547 46.6562 82.5453 48.5898C89.7523 52.457 94.6742 59.4883 95.9047 67.75H107.506C110.495 67.75 113.131 65.2891 113.131 62.125V56.5C113.131 50.3477 108.034 45.25 101.881 45.25ZM56.8812 45.25C67.6039 45.25 76.5687 36.4609 76.5687 25.5625C76.5687 14.8398 67.6039 5.875 56.8812 5.875C45.9828 5.875 37.1937 14.8398 37.1937 25.5625C37.1937 36.4609 45.9828 45.25 56.8812 45.25ZM70.2406 50.875H68.8344C65.143 52.6328 61.1 53.6875 56.8812 53.6875C52.4867 53.6875 48.4437 52.6328 44.7523 50.875H43.3461C32.0961 50.875 23.1312 60.0156 23.1312 71.2656V76.1875C23.1312 80.9336 26.8227 84.625 31.5687 84.625H82.1937C86.7641 84.625 90.6312 80.9336 90.6312 76.1875V71.2656C90.6312 60.0156 81.4906 50.875 70.2406 50.875ZM31.0414 48.5898C28.932 46.6562 26.1195 45.25 23.1312 45.25H11.8812C5.55312 45.25 0.631248 50.3477 0.631248 56.5V62.125C0.631248 65.2891 3.09219 67.75 6.25625 67.75H17.682C18.9125 59.4883 23.8344 52.457 31.0414 48.5898Z"
        fill="white"
      />
    </svg>
  );
}
