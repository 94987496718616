import { Box, Container, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import "./style.css";
import ContactUs from "./ContactUs";

const SendUs = () => {
  const { t } = useTranslation();

  return (
    <section>
      <Box maxW="1600px" mx="auto">
        <Box w="100%" className="parallax">
          <Box w="100%" minH="90vh">
            <Container maxW="1220px" pt="132px">
              <Box
                bg="orange"
                maxW="481.78px"
                ml="auto"
                px="50px"
                py="42.5px"
                rounded="4px"
                position="relative"
              >
                <Text
                  color="white"
                  mb="24.5px"
                  fontWeight="700"
                  fontSize="16px"
                >
                  <Trans>qualityHigh</Trans>
                </Text>
                <Text color="white" fontSize="14px" fontWeight="700">
                  <Trans>vulkanPlusCompany</Trans>
                </Text>
              </Box>
            </Container>
          </Box>
        </Box>
        <ContactUs />
      </Box>
    </section>
  );
};

export default SendUs;
