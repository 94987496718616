import { Box, Modal, ModalBody, ModalContent } from '@chakra-ui/react'
import React from 'react'

const YoutubeVideo = ({ overlay, isOpen, onClose }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      {overlay}
      <ModalContent
        maxWidth={['300px', '375px', '560px', '760px']}
        height={['200px', '260px', '360px', '415px']}
        bg="black"
      >
        <ModalBody pt="0" pb="0" pl="0" pr="0" position="relative">
          <Box
            px="10px"
            py="3px"
            color="white"
            cursor="pointer"
            position="absolute"
            top="-25px"
            right="-5px"
            onClick={onClose}
            fontWeight="700"
          >
            ⨉
          </Box>
          <iframe
            style={{ width: '100%', height: '100%' }}
            width="760"
            height="415"
            src="https://www.youtube.com/embed/W_Su-tOEnkg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default YoutubeVideo
