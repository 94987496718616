import { Box, Container, Text, Image } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import Logo from "../../assets/svg/SvgVulkanFooter";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <Box bg="#3f3f3f" py="80px">
        <Container maxW="1220px">
          <Box
            display="flex"
            flexDir={{ base: "column", md: "row" }}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "center", md: "start" }}
            mb="143px"
          >
            <Box mb={{ base: "70px", md: "0" }}>
              <Text mb="24px" fontSize="22px" fontWeight="400" color="white">
                <Trans>callUs</Trans>:
              </Text>
              <Text fontSize="14px" color="#9B9B9B" fontWeight="400" pb="15px">
                <Trans>phone</Trans>: +996 (312) 882 263
              </Text>
              {/* <Text fontSize="14px" color="#9B9B9B" fontWeight="400">
                <Trans>mobile</Trans>: +996 (502) 221 074
              </Text> */}
            </Box>
            <Box mb={{ base: "70px", md: "0" }}>
              <Logo />
            </Box>
            <Box>
              <Text mb="24px" fontSize="22px" fontWeight="400" color="white">
                <Trans>contactUs</Trans>:
              </Text>
              <Text fontSize="14px" color="#9B9B9B" fontWeight="400" pb="15px">
                vulkanplyus@mail.ru
              </Text>
              {/* <Text fontSize="14px" color="#9B9B9B" fontWeight="400">
                vulkancompany@gmail.com
              </Text> */}
            </Box>
          </Box>
          <Box textAlign={{ base: "center", md: "start" }}>
            <Text fontSize="14px" color="#9B9B9B" fontWeight="400">
              © 2011-2023 «Vulkan Plus».
            </Text>
            <Text
              fontSize="14px"
              textTransform="uppercase"
              color="orange"
              fontWeight="400"
              display="flex"
              alignItems="center"
              justifyContent={{ base: "center", md: "start" }}
            >
              <Trans>developedInMotionWeb</Trans>
              <a href="https://motionweb.dev/" target={'_blank'}>
                <Image
                  ml="10px"
                  maxW="30px"
                  src="https://limitless-connection.com/_next/static/images/logo-3-54f7c1087d10264b209894dc676de6e7.png"
                />
              </a>
            </Text>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
