import { Box, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import HeroImageBottom from '../../../assets/image/heroBottom.webp'

const Hero = () => {
  const { t } = useTranslation()
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  }
  const HeroText = () => {
    return (
      <Box
        h="80vh"
        w="100%"
        position="absolute"
        zIndex="2"
        top="0"
        left="0"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Text
          className="text__vulkan"
          fontSize={['35px', '45px', '55px', '76px']}
          fontWeight="700"
          color="white"
        >
          <Trans>companyName</Trans>
        </Text>
        <Text
          className="text__vulkan"
          fontSize={['15px', '25px', '35px', '46px']}
          fontWeight="300"
          color="silver"
        >
          <Trans>companyDesc</Trans>
        </Text>
      </Box>
    )
  }
  return (
    <section
      style={{ position: 'relative', minHeight: '90vh', overflow: 'hidden' }}
      className="media__hero"
    >
      <Box position="relative" mb="50px">
        <HeroText />
        <Slider {...settings}>
          <Box
            w="100%"
            minH="90vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="media__hero--first"
          ></Box>
          <Box
            w="100%"
            minH="90vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="media__hero--secondary"
          ></Box>
          <Box
            w="100%"
            minH="90vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="media__hero--three"
          ></Box>
          <Box
            w="100%"
            minH="90vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="media__hero--foure"
          ></Box>
        </Slider>
      </Box>
      <Image
        src={HeroImageBottom}
        pos="absolute"
        bottom={{ base: '50px', md: '25px', lg: '22px', xl: '0' }}
      />
    </section>
  )
}

export default Hero
