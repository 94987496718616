import { Box, Image, Text } from "@chakra-ui/react";

import rus from "../../assets/image/russia.png";
import english from "../../assets/image/english.png";
import i18n from "../../i18n/Index";
import "./style.scss";

export default function DropDownLang({ modal, setModal }) {
  const listSelect = [
    {
      item: "Русский",
      flag: rus,
      value: "ru",
    },

    {
      item: "English",
      flag: english,
      value: "en",
    },
  ];

  const onChange = (event) => {
    i18n.changeLanguage(event);
    localStorage.setItem("lang", JSON.stringify(event));
    setModal(false);
  };

  return (
    <Box
      onMouseEnter={() => setModal(true)}
      onMouseLeave={() => setModal(false)}
      className={`dropLang ${modal ? "active" : ""}`}
      zIndex="10"
    >
      <Box
        maxW="155px"
        h="105px"
        bg="white"
        shadow="0px 4px 6px rgba(0, 0, 0, 0.25)"
        roundedBottom="5px"
      >
        <Box pl="30px" mx="auto" display="flex" h="100%" alignItems="center">
          <Box>
            {listSelect.map((el, index) => (
              <Box
                my="14px"
                onClick={() => onChange(el.value)}
                alignItems="center"
                key={index}
                display="flex"
              >
                <Image src={el.flag} w="16px" h="12px" />
                <Text
                  ml="10px"
                  fontSize="14px"
                  color="#A4A4A4"
                  cursor="pointer"
                >
                  {el.item}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
