export default function SvgArrow() {
  return (
    <svg
      width="14"
      height="7"
      viewBox="0 0 14 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.77585 2.56896H0V4.43103H9.77585V6.75861L13.5 3.5L9.77585 0.241379V2.56896Z"
        fill="#EAA93B"
      />
    </svg>
  );
}
