import {
  Box,
  Container,
  Image,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'

import AboutImg from '../../../assets/image/heroImage.webp'
import PlayerIcon from '../../player/PlayerIcon'
import YoutubeVideo from '../../modal/YoutubeVideo'
import TextComponentAbout from './TextComponentAbout'

const AboutCompany = () => {
  const OverlayOne = () => (
    <ModalOverlay backdropFilter="blur(1px) hue-rotate(90deg)" />
  )

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="1px"
    />
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayOne />)
  return (
    <section>
      <Container
        maxW="1220px"
        mt={{ md: '10px' }}
        mb={{ base: '25px', md: '65' }}
        display="flex"
        flexWrap="wrap"
      >
        <TextComponentAbout/>
        <Box
          maxW={{ base: '600px', lg: '50%' }}
          minH="200px"
          mx="auto"
          mt="10px"
          position="relative"
        >
          <Box
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            bg="linear-gradient(180deg, rgba(27, 26, 26, 0.95) 0%, rgba(27, 26, 26, 0) 100%)"
          >
            <Box
              width="60px"
              height="60px"
              borderRadius="100%"
              bg="#ffffff"
              display="flex"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              onClick={() => {
                setOverlay(<OverlayTwo />)
                onOpen()
              }}
            >
              <PlayerIcon />
            </Box>
          </Box>
          <Image src={AboutImg} maxW="100%" alt="aboute_image" />
          <YoutubeVideo isOpen={isOpen} onClose={onClose} overlay={overlay} />
        </Box>
      </Container>
    </section>
  )
}

export default AboutCompany
