import { Box } from "@chakra-ui/react";

import "./style.css";
import SvgPlayer from "../../assets/svg/SvgPlayer";

const PlayerIcon = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      background="#ffffff"
      position="relative"
      className="pulse"
    >
      <SvgPlayer />
    </Box>
  );
};

export default PlayerIcon;
