export default function SvgPhone() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4219 12.5547L11.9844 10.1172C12.0547 9.85938 11.9141 9.57812 11.6562 9.46094L9.03125 8.33594C8.79688 8.24219 8.53906 8.3125 8.375 8.5L7.20313 9.92969C5.42188 9.08594 3.92188 7.60938 3.05469 5.78125L4.48438 4.60938C4.67188 4.44531 4.74219 4.1875 4.64844 3.95313L3.52344 1.32813C3.40625 1.07031 3.125 0.953125 2.86719 1L0.429688 1.5625C0.171875 1.63281 0.0078125 1.84375 0.0078125 2.125C0.0078125 8.125 4.85938 13 10.8828 13C11.1406 13 11.3516 12.8125 11.4219 12.5547Z"
        fill="#9B9B9B"
      />
    </svg>
  );
}
