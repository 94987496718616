import { Box, Container, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const CastSostav = () => {
  const { t } = useTranslation();

  const availableSize = [
    {
      theme: "normalDiameter",
      block1: [{ text1: "20", text2: "25", text3: "30", text4: "40" }],
      block2: [{ text1: "50", text2: "60" }],
      block3: "70",
    },
    {
      theme: "tolerance",
      block1: [{ text1: "+2, -2" }],
      block2: [{ text1: "+3, -3" }],
      block3: "3, -3",
    },
    {
      theme: "surface",
      block1: [{ text1: "45-58 (depends on composition of Cr in allow)" }],
      block2: [{ text1: "45-58 (depends on composition of Cr in allow)" }],
      block3: "45-58 (depends on composition of Cr in allow)",
    },
    {
      theme: "impact",
      block1: [{ text1: "≥12" }],
      block2: [{ text1: "≥12" }],
      block3: "≥12",
    },
  ];

  const materials = [
    { text: "C", chislo: "2,0-3,0" },
    { text: "Si", chislo: "≤0,10" },
    { text: "Mn", chislo: "0,5-1,5" },
    { text: "Cr", chislo: "≤10,14" },
    { text: "P", chislo: "≤0,10" },
    { text: "S", chislo: "≤0,10" },
    { text: "Mo", chislo: "≤0,10" },
    { text: "Cu", chislo: "≤2,0" },
    { text: "Ni", chislo: "≤1,5" },
  ];

  const abgMaterials = [
    { text: "C", chislo: "2,1-3,1" },
    { text: "Si", chislo: "0,5-2,2" },
    { text: "Mn", chislo: "0,5-1,5" },
    { text: "Cr", chislo: "6-10" },
    { text: "P", chislo: "≤0,10" },
    { text: "S", chislo: "≤0,10" },
    { text: "Mo", chislo: "≤1,0" },
    { text: "Cu", chislo: "≤0,8" },
    { text: "Ni", chislo: "≤1,5" },
  ];

  const shortsMaterials = [
    { text: "C", chislo: "2,1-3,6" },
    { text: "Si", chislo: "≤1,2" },
    { text: "Mn", chislo: "0,5-1,5" },
    { text: "Cr", chislo: "1,3" },
    { text: "P", chislo: "≤0,10" },
    { text: "S", chislo: "≤1,0" },
    { text: "Mo", chislo: "≤1,0" },
    { text: "Cu", chislo: "≤0,8" },
    { text: "Ni", chislo: "-" },
  ];

  const Tables = ({ material, text, text1 }) => {
    return (
      <Box>
        <Box
          fontFamily="Roboto Slab"
          fontWeight="600"
          fontSize={["12px", "16px", "18px"]}
          bg="rgba(245,167,0,1)"
          py={["10px", "20px"]}
        >
          <Text textAlign="center" px={["10px"]}>
            <Trans>{text}</Trans>
          </Text>
          <Text textAlign="center" px={["10px"]}>
            <Trans>{text1}</Trans>
          </Text>
        </Box>
        <Box
          display="flex"
          fontFamily="Roboto Slab"
          fontWeight="600"
          fontSize={["10px", "12px", "14px"]}
        >
          {material.map((el, idx) => (
            <Box key={idx} width="11.11%" display="flex" flexDirection="column">
              <Box
                px={["2px", "2px", "10px"]}
                py="10px"
                border="solid 0.5px"
                borderColor="rgba(245,167,0,1)"
              >
                <Text textAlign="center">{el.text}</Text>
              </Box>
              <Box
                px={["2px", "3px", "5px", "10px"]}
                py="10px"
                border="solid 0.5px"
                borderColor="rgba(245,167,0,1)"
                h="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text textAlign="center">{el.chislo}</Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <section>
      <Container maxW="1220px" pb={["20px", "30px"]}>
        <Box>
          <Box
            border="solid"
            borderColor="rgba(245,167,0,1)"
            borderLeft="0px"
            borderRight="0px"
            borderTop="0px"
            mb="30px"
          >
            <Text
              textAlign="center"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["20px", "23px"]}
              lineHeight="34px"
              py={["20px", "30px"]}
              px={["10px", "20px"]}
            >
              <Trans>castGrindingBallsAre</Trans>
            </Text>
          </Box>
          <Box>
            <Box
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["12px", "16px", "18px"]}
              bg="rgba(245,167,0,1)"
              py={["10px", "10px", "20px"]}
            >
              <Text textAlign="center" px={["10px"]}>
                <Trans>available</Trans>
              </Text>
            </Box>
            <Box
              display="flex"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["10px", "12px", "14px"]}
            >
              {availableSize.map((el, idx) => (
                <Box key={idx} w="33.33%">
                  <Box
                    fontFamily="Roboto Slab"
                    fontWeight="600"
                    fontSize={["12px", "14px", "16px", "18px"]}
                    bg="rgba(245,167,0,1)"
                    h={["70px", "50px"]}
                  >
                    <Text textAlign="center">
                      <Trans>{el.theme}</Trans>
                    </Text>
                  </Box>
                  <Box w="100%">
                    {el.block1.map((elem, idx) => (
                      <Box
                        key={idx}
                        border="solid 1px"
                        borderColor="rgba(245,167,0,1)"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        px={["2px", "10px", "20px"]}
                        h={["110px", "100px"]}
                      >
                        <Text textAlign="center">{elem.text1}</Text>
                        <Text>{elem?.text2}</Text>
                        <Text>{elem?.text3}</Text>
                        <Text>{elem?.text4}</Text>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    w="100%"
                    border="solid 1px"
                    borderColor="rgba(245,167,0,1)"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    px={["2px", "10px", "20px"]}
                    h={["90px", "60px"]}
                  >
                    {el.block2.map((elem, idx) => (
                      <Box key={idx}>
                        <Text textAlign="center">{elem.text1}</Text>
                        <Text>{elem?.text2}</Text>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    w="100%"
                    h={["90px", "60px"]}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="solid 1px"
                    borderColor="rgba(245,167,0,1)"
                  >
                    <Text textAlign="center">{el.block3}</Text>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box h="3px" w="100%" my="30px" bg="rgba(245,167,0,1)"></Box>
        </Box>
        <Tables
          material={materials}
          text={"theChemical"}
          text1={"highChromium"}
        />
        <Box h="3px" w="100%" my="30px" bg="rgba(245,167,0,1)"></Box>
        <Tables material={abgMaterials} text={""} text1={"medium"} />
        <Box h="3px" w="100%" my="30px" bg="rgba(245,167,0,1)"></Box>
        <Tables material={shortsMaterials} text={""} text1={"lowChromium"} />
      </Container>
    </section>
  );
};

export default CastSostav;
