import { Box, Container, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const UsedRolled = () => {
  const { t } = useTranslation();

  const diametr = [
    { text: "normalDiameter" },
    { text: "tolerance" },
    { text: "surface" },
    { text: "impact" },
  ];

  const chislo = [
    { text1: "20", text2: "25", text3: "30", text4: "40" },
    { text1: "+2, -1" },
    { text1: "60-67" },
    { text1: "≥12" },
  ];

  const material = [
    { text: "C", chislo: "0,47-0,55" },
    { text: "Si", chislo: "0,17-0,37" },
    { text: "Mn", chislo: "0,5-0,8" },
    { text: "Ni", chislo: "≤0,25" },
    { text: "S", chislo: "≤0,035" },
    { text: "P", chislo: "≤0,035" },
    { text: "Cr", chislo: "≤0,25" },
    { text: "Cu", chislo: "≤0,25" },
    { text: "As", chislo: "≤0,08" },
    { text: "Fe", chislo: "~97" },
  ];
  return (
    <section>
      <Container maxW="1220px">
        <Box>
          <Box
            border="solid"
            borderColor="rgba(245,167,0,1)"
            borderLeft="0px"
            borderRight="0px"
            borderTop="0px"
            mb="30px"
          >
            <Text
              textAlign="center"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["20px", "23px"]}
              lineHeight="34px"
              py={["20px", "30px"]}
              px={["10px", "20px"]}
            >
              <Trans>used</Trans>
            </Text>
          </Box>
          <Box>
            <Box
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["12px", "16px", "18px"]}
              bg="rgba(245,167,0,1)"
              pt={["10px", "20px"]}
            >
              <Text textAlign="center" px={["10px"]}>
                <Trans>available</Trans>
              </Text>
              <Box display="flex">
                {diametr.map((el, idx) => (
                  <Box
                    width="33.33%"
                    key={idx}
                    px={["2px", "10px", "20px"]}
                    py="10px"
                  >
                    <Text textAlign="center">
                      <Trans>{el.text}</Trans>
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              display="flex"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["12px", "14px"]}
            >
              {chislo.map((el, idx) => (
                <Box
                  key={idx}
                  w="33.33%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  border="solid 1px"
                  borderColor="rgba(245,167,0,1)"
                >
                  <Text textAlign="center">{el.text1}</Text>
                  <Text textAlign="center">{el?.text2}</Text>
                  <Text textAlign="center">{el?.text3}</Text>
                  <Text textAlign="center">{el?.text4}</Text>
                </Box>
              ))}
            </Box>
          </Box>
          <Box h="3px" w="100%" my="30px" bg="rgba(245,167,0,1)"></Box>
          <Box>
            <Box
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["12px", "16px", "18px"]}
              bg="rgba(245,167,0,1)"
              py={["10px", "20px"]}
            >
              <Text textAlign="center" px={["10px"]}>
                <Trans>chemical</Trans>
              </Text>
            </Box>
            <Box
              display="flex"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["10px", "12px", "14px"]}
            >
              {material.map((el, idx) => (
                <Box
                  key={idx}
                  width="10%"
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    px={["2px", "2px", "10px"]}
                    py="10px"
                    border="solid 0.5px"
                    borderColor="rgba(245,167,0,1)"
                  >
                    <Text textAlign="center">{el.text}</Text>
                  </Box>
                  <Box
                    px={["1px", "2px", "5px", "10px"]}
                    py="10px"
                    border="solid 0.5px"
                    borderColor="rgba(245,167,0,1)"
                    h="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text textAlign="center">{el.chislo}</Text>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box h="3px" w="100%" my="30px" bg="rgba(245,167,0,1)"></Box>
        </Box>
      </Container>
    </section>
  );
};

export default UsedRolled;
