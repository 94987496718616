import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  ForgedAviable,
  HeroComponent,
  SendUs,
  VideoPlusText,
} from "../../components";
import ForgedImg from "../../assets/img/forded.webp";
import ForgedMainImg from "../../assets/img/forgedHero.webp";
import Loading from "../../components/loading/Loading";

const ForgedPage = () => {
  const text = "forgedGrindingBalls";
  const desc = "forgedBalls";

  const [load, setLoad] = useState(true);
  useEffect(() => {
    setLoad(false);
  }, []);

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <HeroComponent text={text} image={ForgedMainImg} />
          <VideoPlusText text={text} desc={desc} image={ForgedImg} />
          <ForgedAviable />
          <SendUs />
        </>
      )}
    </>
  );
};

export default ForgedPage;
