import {
  Box,
  Container,
  Text,
  Image,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import YoutubeVideo from "../modal/YoutubeVideo";
import PlayerIcon from "../player/PlayerIcon";

const VideoPlusText = ({ text, desc, image }) => {
  const { t } = useTranslation();

  const OverlayOne = () => (
    <ModalOverlay backdropFilter="blur(1px) hue-rotate(90deg)" />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="1px"
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = useState(<OverlayOne />);

  return (
    <section>
      <Container maxW="1220px">
        <Box
          py={["20px", "40px"]}
          display="flex"
          justifyContent={["center", "center", "space-between"]}
          flexDirection={["column", "column", "row"]}
        >
          <Box
            maxWidth={["", "100%", "50%", "360px", "456px"]}
            position="relative"
            mt="10px"
            mb="10px"
            overflow="hidden"
            borderRadius="10px"
            mr={["", "", "10px"]}
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              bottom="0"
              right="0"
              zIndex="20"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg="linear-gradient(180deg, rgba(27, 26, 26, 0.95) 0%, rgba(27, 26, 26, 0) 100%)"
            >
              <Box
                width="60px"
                height="60px"
                borderRadius="100%"
                bg="#ffffff"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={() => {
                  setOverlay(<OverlayTwo />);
                  onOpen();
                }}
              >
                <PlayerIcon />
              </Box>
            </Box>
            <Image src={image} alt="Photo_ball" width="100%" />
          </Box>
          <YoutubeVideo isOpen={isOpen} onClose={onClose} overlay={overlay} />
          <Box
            background="#F5A700"
            py="10px"
            cursor="pointer"
            px={["10px", "20px"]}
            w={["", "100%", "50%", "60%", "70%"]}
            display="flex"
            justifyContent="center"
            alignItems={["", "center"]}
          >
            <Box>
              <Text
                textAlign="center"
                fontFamily="Oswald"
                fontWeight="700"
                color="white"
                fontSize={["40px", "48px"]}
                lineHeight={["48px", "58px"]}
              >
                <Trans>{text}</Trans>
              </Text>
              <Text
                mt={["10px", "20px"]}
                textAlign="center"
                fontFamily="Roboto"
                fontWeight="400"
                color="white"
                fontSize="16px"
              >
                <Trans>{desc}</Trans>
              </Text>
            </Box>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default VideoPlusText;
