import { Box, Container, Text, Image, Button } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import Slider from 'react-slick'

import Image1 from '../../../assets/image/img.webp'
import Image2 from '../../../assets/image/img1.webp'
import Image3 from '../../../assets/image/image2.webp'
import Image4 from '../../../assets/image/image3.webp'
import Image5 from '../../../assets/image/image4.webp'
import Image6 from '../../../assets/image/image6.webp'
import './style.css'

const OurProduction = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const list = [
    {
      image: Image1,
      title: 'forgedGrindingBalls',
      link: 'forged-balls',
    },
    {
      image: Image2,
      title: 'castGrindingBalls',
      link: 'cast-balls',
    },
    {
      image: Image3,
      title: 'rolledGrindingBalls',
      link: 'rolled-balls',
    },
    {
      image: Image4,
      title: 'modelCasting',
      link: 'model',
    },
    {
      image: Image5,
      title: 'grindingBalls',
      link: 'grinding',
    },
    {
      image: Image6,
      title: 'laboratoryServices',
      link: 'laboratory',
    },
  ]

  return (
    <section>
      <Container maxW="1220px" overflow="hidden">
        <Text
          textAlign="center"
          mb="14px"
          color="#1B1A1A"
          fontWeight="700"
          fontSize="48px"
        >
          <Trans>ourProduct</Trans>
        </Text>
        <Text
          mb="15px"
          lineHeight="30.39px"
          fontWeight="400"
          fontSize="18px"
          maxW="1166px"
          mx="auto"
          textAlign="center"
        >
          <Trans>theProduction</Trans>
        </Text>
        <Box w="100%" h="2px" bg="orange" mb="19px"></Box>
        <Text
          mb="35px"
          textAlign="center"
          fontSize="20px"
          fontWeight="500"
          color="orange"
        >
          <Trans>pproductionOfGrinding</Trans>
        </Text>
        <Box>
          <Slider {...settings}>
            {list.map((el, index) => (
              <Box key={index} px="10px" mb="10px">
                <Image
                  src={el.image}
                  minH={{ md: '200px' }}
                  alt="no_image"
                  w="100%"
                />
                <Box display="flex" justifyContent="center" mt="10px">
                  <Button
                    w="100%"
                    bg="orange"
                    py="25px"
                    color="white"
                    fontWeight="500"
                    onClick={() => {
                      navigate(`/${el.link}`)
                      window.scroll(0, 0)
                    }}
                  >
                    <Trans>{el.title}</Trans>
                  </Button>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
        <Box w="100%" h="2px" bg="orange" mt="45px"></Box>
      </Container>
    </section>
  )
}

export default OurProduction
