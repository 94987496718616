import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const Loading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="white"
      w="100%"
      h="90vh"
    >
      <Text color="#f5a700" textAlign="center" fontSize="30px">
        Loading...
      </Text>
    </Box>
  )
}

export default Loading
