import React from 'react'

const SvgPencil = () => {
  return (
    <svg
      style={{ width: '100%' }}
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.0062 78.4531C81.7562 78.7031 81.7562 78.9531 81.7562 79.2031V93.3281H37.7562V49.3281H67.8812C68.1312 49.3281 68.3812 49.3281 68.6312 49.0781L72.6312 45.0781C73.2562 44.4531 72.7562 43.3281 71.8812 43.3281H37.7562C34.3812 43.3281 31.7562 46.0781 31.7562 49.3281V93.3281C31.7562 96.7031 34.3812 99.3281 37.7562 99.3281H81.7562C85.0062 99.3281 87.7562 96.7031 87.7562 93.3281V75.2031C87.7562 74.3281 86.6312 73.8281 86.0062 74.4531L82.0062 78.4531ZM101.506 53.3281C104.381 50.4531 104.381 45.8281 101.506 42.9531L96.1312 37.5781C93.2562 34.7031 88.6312 34.7031 85.7562 37.5781L53.0062 70.3281L51.7562 81.7031C51.3812 84.9531 54.1312 87.7031 57.3812 87.3281L68.7562 86.0781L101.506 53.3281ZM89.2562 57.0781L66.0062 80.3281L57.7562 81.3281L58.7562 73.0781L82.0062 49.8281L89.2562 57.0781ZM97.2562 47.2031C97.8812 47.7031 97.8812 48.4531 97.3812 49.0781L93.5062 52.9531L86.2562 45.5781L90.0062 41.8281C90.5062 41.2031 91.3812 41.2031 91.8812 41.8281L97.2562 47.2031Z"
        fill="#FFAE00"
      />
      <rect
        x="2.15625"
        y="1.82812"
        width="131"
        height="131"
        stroke="#FFAE00"
        strokeWidth="3"
      />
    </svg>
  )
}

export default SvgPencil
