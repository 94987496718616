import { Container } from "@chakra-ui/react";
import React from "react";

import HomeOurPhoto from "../components-home/ourPhotos/homeOurPhoto";

const OurPhotosComponent = () => {
  return (
    <section>
      <Container maxW="1220px" py={['20px', '30px']}>
        <HomeOurPhoto />
      </Container>
    </section>
  )
};

export default OurPhotosComponent;
