import React from 'react';
import { Box, Image } from '@chakra-ui/react'
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

import Image1 from '../../../assets/image/photo1.webp'
import Image2 from '../../../assets/image/photo2.webp'
import Image3 from '../../../assets/image/photo3.webp'
import Image4 from '../../../assets/image/photo4.webp'
import Image5 from '../../../assets/image/photo5.webp'
import Image7 from '../../../assets/image/photo7.webp'
import Image8 from '../../../assets/image/photo8.webp'
import Image9 from '../../../assets/image/Завод-форм цех 3.webp'
import Image10 from '../../../assets/image/photo10.webp'
import Image11 from '../../../assets/image/photo11.webp'
import Image15 from '../../../assets/image/photo14.webp'
import Image16 from '../../../assets/image/Завод-форм цех 2.webp'
import Image14 from '../../../assets/image/photo17.webp'
import Image18 from '../../../assets/image/Завод-форм цех.webp'
import Image21 from '../../../assets/image/Завод-форм цех 4.webp'

const HomeOurPhoto = () => {
    const images = [
      {
        src: Image1,
        type: 'image',
      },
      {
        src: Image2,
        type: 'image',
      },
      {
        src: Image3,
        type: 'image',
      },
      {
        src: Image4,
        type: 'image',
      },
      {
        src: Image5,
        type: 'image',
      },
      {
        src: Image7,
        type: 'image',
      },
      {
        src: Image8,
        type: 'image',
      },
      {
        src: Image9,
        type: 'image',
      },
      {
        src: Image10,
        type: 'image',
      },
      {
        src: Image11,
        type: 'image',
      },
      {
        src: Image14,
        type: 'image',
      },
      {
        src: Image15,
        type: 'image',
      },
      {
        src: Image16,
        type: 'image',
      },
      {
        src: Image18,
        type: 'image',
      },
      {
        src: Image21,
        type: 'image',
      },
    ]

    const handleClick = (index) => {
      new Fancybox(images, { startIndex: index })
    }
    return (
      <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
        {images.map((el, index) => (
          <Box maxW="369px" key={index} mb="20px">
            <Image
              src={el.src}
              cursor="pointer"
              w="100%"
              onClick={() => {
                handleClick(index)
              }}
            />
          </Box>
        ))}
      </Box>
    )
};

export default HomeOurPhoto;
