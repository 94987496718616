import { Box, Container, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Photos from "./Photos";

const OurPhotos = () => {
  const { t } = useTranslation();

  return (
    <section>
      <Container maxW="1220px">
        <Text
          textAlign="center"
          mb="14px"
          color="#1B1A1A"
          fontWeight="700"
          fontSize="48px"
        >
          <Trans>ourPhoto</Trans>
        </Text>
        <Box w="100%" h="2px" bg="orange" mb="19px"></Box>
        <Photos />
      </Container>
    </section>
  );
};

export default OurPhotos;
