import { Box } from '@chakra-ui/react'
import React from 'react'
import { Spinner } from '@chakra-ui/react'

const LoadBlock = () => {
  return (
    <Box
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex="20"
      borderRadius="10px"
      bg="gray.100"
    >
      <Spinner
        thickness={['3px', '4px']}
        speed="0.65s"
        emptyColor="gray.200"
        color="#F5A700"
        size={['lg', 'xl']}
      />
    </Box>
  )
}

export default LoadBlock
