import React from 'react'

const SvgPlayer = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1284 6.79883L2.81592 0.705078C1.96631 0.207031 0.706543 0.705078 0.706543 1.90625V14.0938C0.706543 15.207 1.87842 15.8809 2.81592 15.3242L13.1284 9.23047C14.0366 8.67383 14.0366 7.35547 13.1284 6.79883Z"
        fill="#222222"
      />
    </svg>
  )
}

export default SvgPlayer
