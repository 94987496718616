import React, { useEffect, useState } from "react";
import {
  CastSostav,
  HeroComponent,
  SendUs,
  VideoPlusText,
} from "../../components";
import CastImg from "../../assets/img/rolled.webp";
import CastHero from "../../assets/img/castHero.webp";
import Loading from "../../components/loading/Loading";

const CastPage = () => {
  const text = "castGrindingBalls";
  const desc = "castGrindingDesc";

  const [load, setLoad] = useState(true);
  useEffect(() => {
    setLoad(false);
  }, []);

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <HeroComponent text={text} image={CastHero} />
          <VideoPlusText text={text} desc={desc} image={CastImg} />
          <CastSostav />
          <SendUs />
        </>
      )}
    </>
  );
};

export default CastPage;
