export default function SvgInstagram() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="#222222" />
      <path
        d="M15.5 12.0059C13.625 12.0059 12.1309 13.5293 12.1309 15.375C12.1309 17.25 13.625 18.7441 15.5 18.7441C17.3457 18.7441 18.8691 17.25 18.8691 15.375C18.8691 13.5293 17.3457 12.0059 15.5 12.0059ZM15.5 17.5723C14.2988 17.5723 13.3027 16.6055 13.3027 15.375C13.3027 14.1738 14.2695 13.207 15.5 13.207C16.7012 13.207 17.668 14.1738 17.668 15.375C17.668 16.6055 16.7012 17.5723 15.5 17.5723ZM19.7773 11.8887C19.7773 11.4492 19.4258 11.0977 18.9863 11.0977C18.5469 11.0977 18.1953 11.4492 18.1953 11.8887C18.1953 12.3281 18.5469 12.6797 18.9863 12.6797C19.4258 12.6797 19.7773 12.3281 19.7773 11.8887ZM22.0039 12.6797C21.9453 11.625 21.7109 10.6875 20.9492 9.92578C20.1875 9.16406 19.25 8.92969 18.1953 8.87109C17.1113 8.8125 13.8594 8.8125 12.7754 8.87109C11.7207 8.92969 10.8125 9.16406 10.0215 9.92578C9.25977 10.6875 9.02539 11.625 8.9668 12.6797C8.9082 13.7637 8.9082 17.0156 8.9668 18.0996C9.02539 19.1543 9.25977 20.0625 10.0215 20.8535C10.8125 21.6152 11.7207 21.8496 12.7754 21.9082C13.8594 21.9668 17.1113 21.9668 18.1953 21.9082C19.25 21.8496 20.1875 21.6152 20.9492 20.8535C21.7109 20.0625 21.9453 19.1543 22.0039 18.0996C22.0625 17.0156 22.0625 13.7637 22.0039 12.6797ZM20.5977 19.2422C20.3926 19.8281 19.9238 20.2676 19.3672 20.502C18.4883 20.8535 16.4375 20.7656 15.5 20.7656C14.5332 20.7656 12.4824 20.8535 11.6328 20.502C11.0469 20.2676 10.6074 19.8281 10.373 19.2422C10.0215 18.3926 10.1094 16.3418 10.1094 15.375C10.1094 14.4375 10.0215 12.3867 10.373 11.5078C10.6074 10.9512 11.0469 10.5117 11.6328 10.2773C12.4824 9.92578 14.5332 10.0137 15.5 10.0137C16.4375 10.0137 18.4883 9.92578 19.3672 10.2773C19.9238 10.4824 20.3633 10.9512 20.5977 11.5078C20.9492 12.3867 20.8613 14.4375 20.8613 15.375C20.8613 16.3418 20.9492 18.3926 20.5977 19.2422Z"
        fill="white"
      />
    </svg>
  );
}
