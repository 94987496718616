export default function SvgFacebook() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="#222222" />
      <path
        d="M18.9863 16.3125L19.3965 13.6172H16.7891V11.8594C16.7891 11.0977 17.1406 10.3945 18.3125 10.3945H19.5137V8.08008C19.5137 8.08008 18.4297 7.875 17.4043 7.875C15.2656 7.875 13.8594 9.19336 13.8594 11.5371V13.6172H11.457V16.3125H13.8594V22.875H16.7891V16.3125H18.9863Z"
        fill="white"
      />
    </svg>
  );
}
