import React, { useEffect, useState } from "react";
import {
  HeroComponent,
  SendUs,
  UsedRolled,
  VideoPlusText,
} from "../../components";
import RolledImg from "../../assets/img/cast.webp";
import RolledHero from "../../assets/img/rolledHero.webp";
import Loading from "../../components/loading/Loading";

const RolledPage = () => {
  const text = "rolled";
  const desc = "rolledGrinding";

  const [load, setLoad] = useState(true);
  useEffect(() => {
    setLoad(false);
  }, []);

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <HeroComponent text={text} image={RolledHero} />
          <VideoPlusText text={text} desc={desc} image={RolledImg} />
          <UsedRolled />
          <SendUs />
        </>
      )}
    </>
  );
};

export default RolledPage;
