export default function SvgClock() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.28906 0.828125C3.81055 0.828125 0.992188 3.64648 0.992188 7.125C0.992188 10.6035 3.81055 13.4219 7.28906 13.4219C10.7676 13.4219 13.5859 10.6035 13.5859 7.125C13.5859 3.64648 10.7676 0.828125 7.28906 0.828125ZM9.625 8.77539L9.11719 9.41016C9.04102 9.51172 8.93945 9.58789 8.78711 9.58789C8.71094 9.58789 8.60938 9.53711 8.55859 9.48633L6.85742 8.2168C6.60352 8.03906 6.47656 7.75977 6.47656 7.42969V3.46875C6.47656 3.26562 6.6543 3.0625 6.88281 3.0625H7.69531C7.89844 3.0625 8.10156 3.26562 8.10156 3.46875V7.125L9.57422 8.2168C9.65039 8.29297 9.72656 8.39453 9.72656 8.52148C9.72656 8.62305 9.67578 8.72461 9.625 8.77539Z"
        fill="#9B9B9B"
      />
    </svg>
  );
}
