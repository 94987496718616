export default function SvgMail() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9316 4.47461C12.3477 4.93164 11.6113 5.49023 9.02148 7.36914C8.51367 7.75 7.57422 8.58789 6.68555 8.58789C5.77148 8.58789 4.85742 7.75 4.32422 7.36914C1.73438 5.49023 0.998047 4.93164 0.414062 4.47461C0.3125 4.39844 0.185547 4.47461 0.185547 4.60156V9.78125C0.185547 10.4668 0.71875 11 1.4043 11H11.9668C12.627 11 13.1855 10.4668 13.1855 9.78125V4.60156C13.1855 4.47461 13.0332 4.39844 12.9316 4.47461ZM6.68555 7.75C7.26953 7.77539 8.10742 7.01367 8.53906 6.70898C11.916 4.27148 12.1699 4.04297 12.9316 3.43359C13.084 3.33203 13.1855 3.1543 13.1855 2.95117V2.46875C13.1855 1.80859 12.627 1.25 11.9668 1.25H1.4043C0.71875 1.25 0.185547 1.80859 0.185547 2.46875V2.95117C0.185547 3.1543 0.261719 3.33203 0.414062 3.43359C1.17578 4.04297 1.42969 4.27148 4.80664 6.70898C5.23828 7.01367 6.07617 7.77539 6.68555 7.75Z"
        fill="#9B9B9B"
      />
    </svg>
  );
}
