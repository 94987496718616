import { Box, Container, Image } from '@chakra-ui/react'
import React from 'react'

import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

const ModelPhotos = ({ photoModel }) => {
  const handleClick = (index) => {
    new Fancybox(photoModel, { startIndex: index })
  }
  return (
    <sextion>
      <Container maxW="1220px">
        <Box
          display="flex"
          justifyContent={[
            'center',
            'center',
            'space-around',
            'space-around',
            'space-between',
          ]}
          flexWrap="wrap"
          pb={['20px', '30px']}
        >
          {photoModel.map((el, idx) => (
            <Box
              key={idx}
              maxWidth="370px"
              mb={['10px', '15px', '20px']}
              mx="5px"
            >
              <Image
                src={el.src}
                alt="Model_Photo"
                cursor="pointer"
                w="100%"
                onClick={() => {
                  handleClick(idx)
                }}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </sextion>
  )
}

export default ModelPhotos
