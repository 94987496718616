import React from 'react'

const SvgColbo = () => {
  return (
    <svg
      style={{ width: '100%' }}
      width="135"
      height="135"
      viewBox="0 0 135 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M94.3813 86.4219L79.7563 62.7969V43.9219H80.7563C82.3813 43.9219 83.7563 42.6719 83.7563 40.9219V38.9219C83.7563 37.2969 82.3813 35.9219 80.7563 35.9219H54.7563C53.0063 35.9219 51.7563 37.2969 51.7563 38.9219V40.9219C51.7563 42.6719 53.0063 43.9219 54.7563 43.9219H55.7563V62.7969L41.0063 86.4219C37.3813 92.2969 41.6313 99.9219 48.5063 99.9219H86.8813C93.7563 99.9219 98.0063 92.2969 94.3813 86.4219ZM56.8813 75.9219L63.0063 66.2969C63.3813 65.6719 63.6313 64.7969 63.6313 63.9219V43.9219H71.6313V63.9219C71.6313 64.7969 72.0063 65.6719 72.3813 66.2969L78.3813 75.9219H56.8813Z"
        fill="#FFAE00"
      />
      <rect
        x="2.15625"
        y="2.42188"
        width="131"
        height="131"
        stroke="#FFAE00"
        strokeWidth="3"
      />
    </svg>
  )
}

export default SvgColbo
