import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { TbArrowNarrowUp } from "react-icons/tb";

import "./style.scss";

const IconUp = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [fixedMenu, seyFixedMenu] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos > 190) {
      seyFixedMenu(true);
      setPrevScrollPos(prevScrollPos);
    } else {
      seyFixedMenu(false);
      setPrevScrollPos(currentScrollPos);
    }
  };

  const handleClick = () => {
    window.scroll(0, 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  return (
    <Box
      position="fixed"
      w={["40px", "50px"]}
      h={["40px", "50px"]}
      bottom={["0", "30px"]}
      right={["0", "20px"]}
      bg="#f5a700"
      borderRadius={["0", "100%"]}
      cursor="pointer"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={handleClick}
      zIndex="50"
      fontSize="30px"
      color="white"
      className={fixedMenu ? "icon__up" : "icon__down"}
    >
      <TbArrowNarrowUp />
    </Box>
  );
};

export default IconUp;
