import Header from "../header/Header";
import Footer from "../footer/Footer";
import IconUp from "../IconUp/IconUp";

const PageRouting = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <IconUp />
      <Footer />
    </>
  );
};

export default PageRouting;
