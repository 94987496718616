import { Box, Button, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ModalAboutCompany from './ModalAboutCompany'

const TextComponentAbout = () => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box w={{ base: '100%', lg: '50%' }} pr="8px" mt="10px">
      <ModalAboutCompany isOpen={isOpen} onClose={onClose} />
      <Text
        mb="14px"
        color="#1B1A1A"
        textAlign="center"
        fontWeight="700"
        fontSize="48px"
      >
        <Trans>AboutCompany</Trans>
      </Text>
      <Text mb="25px" lineHeight="30.39px" fontWeight="400" fontSize="18px">
        <Trans>theCompany</Trans>
      </Text>
      <Text mb="25px" lineHeight="30.39px" fontWeight="400" fontSize="18px">
        <Trans>theMainActivity</Trans>
      </Text>
      <Box
        display="flex"
        mb="10px"
        justifyContent={{ base: 'center', md: 'flex-start' }}
      >
        <Button bg="orange" onClick={onOpen} fontSize="17px" px="20px" color="white">
          <Trans>btnPodrob</Trans>
        </Button>
      </Box>
    </Box>
  )
}

export default TextComponentAbout
