export default function SvgCalendar() {
  return (
    <svg
      width="80"
      height="91"
      viewBox="0 0 80 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.8187 11.5H62.3812V2.35938C62.3812 1.30469 61.3266 0.25 60.2719 0.25H53.2406C52.0102 0.25 51.1312 1.30469 51.1312 2.35938V11.5H28.6312V2.35938C28.6312 1.30469 27.5766 0.25 26.5219 0.25H19.4906C18.2602 0.25 17.3812 1.30469 17.3812 2.35938V11.5H8.94375C4.19765 11.5 0.506248 15.3672 0.506248 19.9375V81.8125C0.506248 86.5586 4.19765 90.25 8.94375 90.25H70.8187C75.3891 90.25 79.2562 86.5586 79.2562 81.8125V19.9375C79.2562 15.3672 75.3891 11.5 70.8187 11.5ZM69.7641 81.8125H9.99844C9.29531 81.8125 8.94375 81.4609 8.94375 80.7578V28.375H70.8187V80.7578C70.8187 81.4609 70.2914 81.8125 69.7641 81.8125ZM60.4477 46.6562C61.1508 45.7773 61.1508 44.5469 60.4477 43.668L56.4047 39.625C55.7016 38.7461 54.2953 38.7461 53.4164 39.625L33.9047 58.9609L26.1703 51.2266C25.2914 50.3477 24.0609 50.3477 23.182 51.2266L19.1391 55.0938C18.4359 55.9727 18.4359 57.3789 19.1391 58.082L32.3227 71.4414C33.2016 72.3203 34.6078 72.3203 35.3109 71.4414L60.4477 46.6562Z"
        fill="white"
      />
    </svg>
  );
}
