import { Box, Container, Image, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { Trans } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";

import "./style.scss";
import rus from "../../assets/image/russia.png";
import english from "../../assets/image/english.png";
import i18n from "../../i18n/Index";
import { listSvg } from "./helper";

export default function MobileMenu({ className, setOpenBurger }) {
  const [menuLinks, setMenuLinks] = useState(false);
  const [menuLanguage, setMenuLanguage] = useState(false);
  const listLanguage = [
    {
      item: "Русский",
      flag: rus,
      value: "ru",
    },

    {
      item: "English",
      flag: english,
      value: "en",
    },
  ];

  const listSelect = [
    {
      link: "/forged-balls",
      item: "forgedGrindingBalls",
    },

    {
      link: "/cast-balls",
      item: "castGrindingBalls",
    },
    {
      link: "/rolled-balls",
      item: "rolled",
    },
    {
      link: "/model",
      item: "modelCasting",
    },
    {
      link: "/grinding",
      item: "grindingBalls",
    },
    {
      link: "/laboratory",
      item: "laboratoryServices",
    },
  ];

  const openCloseMenu = () => {
    setMenuLinks(!menuLinks);
  };

  const openCloseLang = () => {
    setMenuLanguage(!menuLanguage);
  };

  const handleClick = () => {
    setMenuLinks(false);
    setOpenBurger(false);
    setMenuLanguage(false);
  };

  const onChange = (event) => {
    i18n.changeLanguage(event);
    localStorage.setItem("lang", JSON.stringify(event));
    setOpenBurger(false);
    setMenuLinks(false);
    setMenuLanguage(false);
  };

  return (
    <Box
      bg="white"
      h="100vh"
      left="0"
      right="0"
      className={`mobile-menu ${className}`}
      position="fixed"
      zIndex="50"
      display="flex"
    >
      <Container maxW="1220px" pt="10px">
        <Box display="flex" flexDir="column">
          <Box>
            <Box
              my="18px"
              fontWeight="700"
              fontSize={{ base: '17px', sm: '19px', md: '22px' }}
              cursor="pointer"
              className="item_drop"
            >
              <NavLink to="/">
                <Text onClick={handleClick}>
                  <Trans>AboutCompany</Trans>
                </Text>
              </NavLink>
            </Box>
            <Box>
              <Box
                mt="18px"
                fontWeight="700"
                fontSize={{ base: '17px', sm: '19px', md: '22px' }}
                cursor="pointer"
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                className="item_drop"
                onClick={openCloseMenu}
              >
                <Trans>ourProduct</Trans>
                <Box
                  w={{ base: '25px', sm: '30px' }}
                  h={{ base: '25px', sm: '30px' }}
                  borderRadius="5px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  bg={menuLinks ? '#1A1A1A' : '#f5a700'}
                >
                  <IoIosArrowDown style={menuLinks && { rotate: '180deg' }} />
                </Box>
              </Box>
              <Box overflow="hidden">
                <Box
                  px="20px"
                  className={menuLinks ? 'list__menu' : 'list__menu__close'}
                >
                  {listSelect.map((el, idx) => (
                    <Text
                      key={idx}
                      cursor="pointer"
                      fontWeight="600"
                      fontSize={{ base: '16px', sm: '19px', md: '22px' }}
                      mt="8px"
                      py="2px"
                      className="item_drop"
                      onClick={handleClick}
                    >
                      <NavLink to={el.link}>
                        <Trans>{el.item}</Trans>
                      </NavLink>
                    </Text>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box
              my="14px"
              fontWeight="700"
              fontSize={{ base: '17px', sm: '19px', md: '22px' }}
              cursor="pointer"
              className="item_drop"
            >
              <NavLink to="/our-photos">
                <Text onClick={handleClick}>
                  <Trans>ourPhoto</Trans>
                </Text>
              </NavLink>
            </Box>
            <Box
              my="18px"
              fontWeight="700"
              fontSize={{ base: '17px', sm: '19px', md: '22px' }}
              cursor="pointer"
              className="item_drop"
            >
              <NavLink to="/contact">
                <Text onClick={handleClick}>
                  <Trans>contactUs</Trans>
                </Text>
              </NavLink>
            </Box>
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                cursor="pointer"
                onClick={openCloseLang}
              >
                <Box
                  fontWeight="700"
                  fontSize={{ base: '17px', sm: '19px', md: '22px' }}
                  cursor="pointer"
                  className="item_drop"
                >
                  <Image
                    src={i18n.language === 'ru' ? rus : english}
                    w="16px"
                    h="12px"
                    display="inline-block"
                    mr="5px"
                  />
                  <Trans>lang</Trans>
                </Box>
                <Box
                  w={{ base: '25px', sm: '30px' }}
                  h={{ base: '25px', sm: '30px' }}
                  borderRadius="5px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  bg={menuLanguage ? '#1A1A1A' : '#f5a700'}
                >
                  <IoIosArrowDown
                    style={menuLanguage && { rotate: '180deg' }}
                  />
                </Box>
              </Box>
              <Box overflow="hidden">
                <Box
                  px="20px"
                  className={menuLanguage ? 'list__menu' : 'list__menu__close'}
                >
                  {listLanguage.map((el, idx) => (
                    <Box
                      key={idx}
                      cursor="pointer"
                      fontWeight="600"
                      fontSize={{ base: '16px', sm: '19px', md: '22px' }}
                      mt="6px"
                      py="2px"
                      display="flex"
                      alignItems="center"
                      onClick={() => onChange(el.value)}
                    >
                      <Image src={el.flag} alt="language" w="16px" mr="10px" />
                      <Text className="item_drop">{el.item}</Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
