import { Box, Container, Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

const HeroComponent = ({ text, image }) => {
  const { t } = useTranslation();

  return (
    <section>
      <Box
        bgImage={image}
        bgPosition="center"
        bgRepeat="no-repeat"
        w="100%"
        height={["450px", "470px", "492px"]}
        className="media__hero"
      >
        <Box
          bg="linear-gradient(180deg, rgba(27, 26, 26, 0.95) 0%, rgba(27, 26, 26, 0.2) 100%)"
          h="100%"
        >
          <Container maxW="1220px" height="100%">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Text
                fontFamily="Oswald , sans-serif"
                mt={["0", "4rem", "6rem"]}
                textAlign="center"
                color="#ffffff"
                fontWeight="700"
                fontSize={["38px", "60px", "85px"]}
              >
                <Trans>{text}</Trans>
              </Text>
            </Box>
          </Container>
        </Box>
      </Box>
    </section>
  );
};

export default HeroComponent;
