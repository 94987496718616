import React from 'react'
import ContactUs from '../../components-home/sendUs/ContactUs'
import { Box } from '@chakra-ui/react'

const FormContactUs = () => {
  return (
    <section>
      <Box position="relative">
        <ContactUs />
      </Box>
    </section>
  )
}

export default FormContactUs
