import {
  Box,
  Container,
  Image,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import PlayerIcon from "../../player/PlayerIcon";
import SvgArm from "../../../assets/svg/SvgArm";
import SvgCalendar from "../../../assets/svg/SvgCalendar";
import SvgLike from "../../../assets/svg/SvgLike";
import SvgPeople from "../../../assets/svg/SvgPeople";
import SvgTimer from "../../../assets/svg/SvgTimer";
import Vulkan from "../../../assets/image/VulkanLogoWhite.webp";
import YoutubeVideo from "../../modal/YoutubeVideo";
import "./style.css";

const Advantages = () => {
  const OverlayOne = () => (
    <ModalOverlay backdropFilter="blur(1px) hue-rotate(90deg)" />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="1px"
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = useState(<OverlayOne />);
  const { t } = useTranslation();
  const list = [
    {
      svg: <SvgTimer />,
      title: "workExperience",
    },
    {
      svg: <SvgPeople />,
      title: "professionalTeam",
    },
    {
      svg: <SvgArm />,
      title: "customer",
    },
    {
      svg: <SvgCalendar />,
      title: "high",
    },
    {
      svg: <SvgLike />,
      title: "quality",
    },
  ];

  return (
    <Box mb="100px">
      <Container maxW="1220px">
        <Text
          textAlign="center"
          mb="14px"
          color="#1B1A1A"
          fontWeight="700"
          fontSize="48px"
        >
          <Trans>advantage</Trans>
        </Text>
        <Box w="100%" h="2px" bg="orange" mb="35px"></Box>
      </Container>
      <Box
        w="100%"
        display="flex"
        position="relative"
        flexDir={{ base: "column", md: "row" }}
      >
        <Box w={{ base: "100%", md: "50%" }} bg="orange" pt="110px" pb="29px">
          {list.map((el, index) => (
            <Box
              key={index}
              display="flex"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box mb="8px">{el.svg}</Box>
              <Text
                fontSize="28px"
                fontWeight="600"
                color="white"
                mb={{ base: "43px", md: "49px" }}
                textAlign="center"
                px="10px"
              >
                <Trans>{el.title}</Trans>
              </Text>

              <Container>
                <Box w="100%" h="2px" bg="white" mb="35px"></Box>
              </Container>
            </Box>
          ))}
          <Box maxW="316px" mx="auto">
            <Image src={Vulkan} />
          </Box>
        </Box>
        <Box w={{ base: "100%", md: "50%" }} className="parallax">
          <Box
            w="100%"
            h={{ base: "300px", sm: "500px", md: "1494px", xl: "1452px" }}
          ></Box>
        </Box>
        <Box
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          display={["none", "none", "flex"]}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            w={["60px", "80px"]}
            h={["60px", "80px"]}
            bg="#EA9E00"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="100%"
          >
            <Box
              w={["40px", "60px"]}
              h={["40px", "60px"]}
              cursor="pointer"
              onClick={() => {
                setOverlay(<OverlayTwo />);
                onOpen();
              }}
            >
              <PlayerIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      <YoutubeVideo isOpen={isOpen} onClose={onClose} overlay={overlay} />
    </Box>
  );
};

export default Advantages;
