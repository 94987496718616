
import React from 'react'
import { Box, Button, Image } from '@chakra-ui/react'
import { Fancybox } from '@fancyapps/ui'
import { Trans, useTranslation } from 'react-i18next'
import '@fancyapps/ui/dist/fancybox.css'

import Image1 from '../../../assets/image/photo1.webp'
import Image2 from '../../../assets/image/photo2.webp'
import Image3 from '../../../assets/image/photo3.webp'
import { useNavigate } from 'react-router-dom'

const Photos = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const images = [
    {
      src: Image1,
      type: 'image',
    },
    {
      src: Image2,
      type: 'image',
    },
    {
      src: Image3,
      type: 'image',
    },
  ]

  const handleClick = (index) => {
    new Fancybox(images, { startIndex: index })
  }

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
      {images.map((el, index) => (
        <Box maxW="369px" key={index} mb="20px">
          <Image
            src={el.src}
            cursor="pointer"
            w="100%"
            onClick={() => {
              handleClick(index)
            }}
          />
        </Box>
      ))}
      <Box mb="30px">
        <Button
          bg="orange"
          py="20px"
          px="30px"
          color="white"
          fontWeight="500"
          onClick={() => {
            navigate('/our-photos')
          }}
        >
          <Trans>btnPodrob</Trans>
        </Button>
      </Box>
    </Box>
  )
}

export default Photos
