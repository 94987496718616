import { Box, Container, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import SvgColbo from "../../assets/svg/SvgColbo";
import SvgGalochka from "../../assets/svg/SvgGalochka";

const CompanyLaboratory = () => {
  const { t } = useTranslation();

  const laboratory = [
    {
      icon: <SvgColbo />,
      text1: "carrying",
      text2: "determination",
    },
    {
      icon: <SvgGalochka />,
      text1: "determinationOfImpact",
      text2: "based",
    },
  ];
  return (
    <section>
      <Container maxW="1220px">
        <Box>
          <Box
            border="solid"
            borderColor="rgba(245,167,0,1)"
            borderLeft="0px"
            borderRight="0px"
            borderTop="0px"
          >
            <Text
              textAlign="center"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["20px", "23px"]}
              lineHeight="34px"
              py={["20px", "30px"]}
              px={["10px", "20px"]}
            >
              <Trans>theLaboratory</Trans>
            </Text>
          </Box>
          <Box
            py="20px"
            display="flex"
            alignItems="flex-end"
            flexDirection="column"
          >
            {laboratory.map((el, idx) => (
              <Box
                key={idx}
                w={["100%", "100%", "100%", "940px"]}
                display="flex"
                pb="40px"
              >
                <Box maxW={["50px", "110px"]}>{el.icon}</Box>
                <Box pl="20px" py="5px" w="100%">
                  <Text
                    fontFamily="Roboto Slab"
                    fontWeight="600"
                    fontSize="18px"
                    lineHeight="27px"
                  >
                    <Trans>{el.text1}</Trans>
                  </Text>
                  <Box
                    mt="10px"
                    w="100%"
                    border="solid"
                    borderColor="rgba(245,167,0,1)"
                    borderLeft="0px"
                    borderRight="0px"
                    borderTop="0px"
                    py="10px"
                  >
                    <Text
                      fontFamily="Roboto Slab"
                      fontWeight="600"
                      fontSize="18px"
                      lineHeight="27px"
                    >
                      <Trans>{el.text2}</Trans>
                    </Text>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default CompanyLaboratory;
