import SvgFacebook from '../../assets/svg/SvgFacebook'
import SvgInstagram from '../../assets/svg/SvgInstagram'

export const listSvg = [
  {
    svg: <SvgFacebook />,
    link: 'https://www.facebook.com/vulkanplusllc',
  },
  {
    svg: <SvgInstagram />,
    link: 'https://www.instagram.com/vulkanplus_com/',
  },
]
